export function loadGoogleFont(fontName) {
    // Create and append preconnect links
    const preconnectGoogle = document.createElement('link');
    preconnectGoogle.rel = 'preconnect';
    preconnectGoogle.href = 'https://fonts.bunny.net';
    document.head.appendChild(preconnectGoogle);
  
    const preconnectGoogleStatic = document.createElement('link');
    preconnectGoogleStatic.rel = 'preconnect';
    preconnectGoogleStatic.href = 'https://fonts.gstatic.com';
    preconnectGoogleStatic.crossOrigin = 'anonymous';
    document.head.appendChild(preconnectGoogleStatic);
  
    // Create and append the font link
    const fontLink = document.createElement('link');
    fontLink.href = `https://fonts.bunny.net/css2?family=${fontName.replace(' ', '+')}&display=swap`;
    fontLink.rel = 'stylesheet';
    document.head.appendChild(fontLink);

}
  
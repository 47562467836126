
export const elementsOptions = {
    appearance: {
      variables: {
        colorPrimary: "#000000",
        colorBackground: "#ffffff",
        colorText: "#000000",
        colorDanger: "#ff0000",
        fontFamily: "Ideal Sans, system-ui, sans-serif",
        spacingUnit: "5px",
        borderRadius: "10px",
      },
      rules: {
        ".Input:focus": {
          borderColor: "#0E0020",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
        },
      },
    },
  };
import { useEffect } from "react";

const GcashRedirect = () => {
  useEffect(() => {
    const gcashPlayStoreLink =
      "https://play.google.com/store/apps/details?id=com.globe.gcash.android";
    const gcashAppStoreLink = "https://apps.apple.com/app/id520020791"; // Replace with your App Store ID

    // Fallback to the Play Store (Android) or App Store (iOS) if app is not installed
    const gcashdeepLink = window.location.href.split("gcash-redirect?")[1];
    window.location.href = gcashdeepLink;
    setTimeout(() => {
      if (/android/i.test(navigator.userAgent)) {
        window.location.href = gcashPlayStoreLink;
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        window.location.href = gcashAppStoreLink;
      }
    }, 3000);
  }, []);

  return <p>Redirecting...</p>;
};

export default GcashRedirect;

import React, { useState, useEffect, useRef } from "react";
import "../faq/Faq.scss";
import * as PR from "../../prime-modules/index";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import { messageCatalogActions } from "../../store/messageCatalog";
import { getAssetsData } from "../../services/api";
import SubHeader from "../../components/SubHeader";
import SupportMailsSection from "../../components/SupportMailsSection";
import { useToast } from "../../context/ToastContext";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const Faq = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch()
  const { labels, general, faqNew } = useToast()
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [faqActiveIndex, setFaqActiveIndex] = useState(null);
  const [categories, setCategories] = useState({});
  const [faqData, setFaqData] = useState({});
  const [filterFaqs, setFilterFaqs] = useState([]);
  const [layoutType, setLayoutType] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const tab = new URLSearchParams(location.search).get('tab');
  const query = new URLSearchParams(location.search).get('query');
  const hasScrolled = useRef(false);

  useEffect(() => {
    const faqsNew = faqNew?.["faq-new"];
    if (faqNew || faqsNew) {
      setLoading(false);
    }
    if (!faqsNew) return;
    const categoriesData = faqsNew?.categories ? JSON.parse(faqsNew.categories) : {};
    const filteredCategories = Object.keys(categoriesData)
      .filter((key) => categoriesData[key].status !== 0)
      .reduce((acc, key) => {
        acc[key] = categoriesData[key];
        return acc;
      }, {});
    const filteredFaqData = Object.keys(filteredCategories).reduce((acc, key) => {
      if (key.startsWith("FAQNEW")) {
        acc[key] = faqsNew[key] ? JSON.parse(faqsNew[key]) : {};
      }
      return acc;
    }, {});
    const layout = !!faqsNew?.layoutType ? JSON.parse(faqsNew.layoutType) : ""
    const faqKeys = Object.keys(filteredCategories);
    const index = tab ? faqKeys.findIndex(key => filteredCategories?.[key].name?.toLowerCase().includes(tab?.toLowerCase()) || generateSlug(filteredCategories?.[key].name) === tab) : 0;
    if (tab) {
      setActiveIndex(index > 0 ? index : 0);
    }
    setLayoutType(layout)
    setCategories(filteredCategories)
    setFaqData(filteredFaqData);
  }, [faqNew, tab]);

  useEffect(() => {
    let faqs = [];
    if (layoutType !== "Regular") {
      const categoriesKeys = Object.keys(categories);
      const categorykey = categoriesKeys[activeIndex] ? categoriesKeys[activeIndex] : categoriesKeys[0];
      faqs = faqData?.[categorykey] ? faqData?.[categorykey] : [];
    } else {
      faqs = Object.values(faqData).flat();
    }
    if (search) {
      faqs = faqs.filter((qa) =>
        search
          ? qa?.question?.toLowerCase().includes(search.toLowerCase()) ||
          qa?.answer?.toLowerCase().includes(search.toLowerCase())
          : true
      );
    }
    if (query) {
      const findIndex = faqs.findIndex(f => generateSlug(f.question) === query);
      if (findIndex >= 0) {
        setFaqActiveIndex(findIndex);
      }
    }
    setFilterFaqs(faqs);
  }, [faqData, activeIndex, layoutType, categories, search, query])
  useEffect(() => {
    if (hasScrolled.current) return; // Prevents running again
    setTimeout(() => {
      if (query && filterFaqs?.length) {
        const el = document.getElementById(query);
        if (el) {
          const offset = 120; // Adjust this value as needed
          const elementPosition = el.getBoundingClientRect().top + window.scrollY;

          window.scrollTo({
            top: elementPosition - offset,
            behavior: "smooth",
          });
        }
        hasScrolled.current = true; // Ensures it runs only once
      } else if(filterFaqs?.length){
        hasScrolled.current = true; // Ensures it runs only once
      }
    }, 100);
  }, [query, filterFaqs]);

  useEffect(() => {
    const getResponse = (response) => {
      if (response.result === "SUCCESS") {
        dispatch(messageCatalogActions.storeFaq(response.data))
      }
    }
    getAssetsData(i18n.language, 'faq-new', getResponse)
  }, [i18n.language, dispatch]);

  const tabChangeHandler = (index) => {
    const categoriesData = Object.values(categories);
    const tabSlug = generateSlug(categoriesData?.[index]?.name);
    const params = new URLSearchParams();
    if (tabSlug) params.set("tab", tabSlug);
    navigate(`/faq?${params.toString()}`, { replace: true });
  }
  const generateSlug = (text) =>
    text ? text.toLowerCase().replace(/[^\w\s]/g, "").replace(/\s+/g, "-") : "";
  const faqTabChangeHandler = (index) => {
    const fagSlug = generateSlug(filterFaqs[index]?.question);
    if (layoutType !== 'Regular') {
      const categoriesData = Object.values(categories);
      const tabSlug = generateSlug(categoriesData?.[activeIndex]?.name);
      const params = new URLSearchParams();
      if (tabSlug) params.set("tab", tabSlug);
      if (fagSlug) params.set("query", fagSlug);
      navigate(`/faq?${params.toString()}`, { replace: true });
    } else {
      const params = new URLSearchParams();
      if (fagSlug) params.set("query", fagSlug);
      navigate(`/faq?${params.toString()}`, { replace: true });
    }
  }

  return (

    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${general.GEN0001} | ${labels.LBL0136}`}</title>
      </Helmet>

      {/* Header Section */}
      <SubHeader header={labels.LBL0136} subText={labels.LBL0137} />
      <section className="about-blocks-section faq-main">
        <div className="custom-container">
          <div className="faq-section faq-tabs">
            {!loading && (
              <div className={`search-block ${layoutType === "Regular" ? "regular-layout" : ""}`}>
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <PR.InputText
                    value={search}
                    type="search"
                    className="p-inputtext-sm"
                    onChange={(e) => setSearch(e.target.value)}
                    placeholder={labels?.LBL0138}
                  />
                </span>
              </div>
            )}
            {loading ? (
              <div className="loading-container">
                <div className="loading-block">
                  <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                  <p>{labels.LBL0069}</p>
                </div>
              </div>
            ) :
              layoutType === "Regular" ? (
                <>
                  {filterFaqs.length > 0 ? (
                    <PR.Accordion  scrollIntoView activeIndex={faqActiveIndex} onTabChange={e => {
                      setFaqActiveIndex(e.index);
                      faqTabChangeHandler(e.index);
                    }}>
                      {filterFaqs.map((qa, j) => (qa?.question &&
                        <PR.AccordionTab header={qa.question} key={j} id={generateSlug(qa.question)}>
                          <div dangerouslySetInnerHTML={{ __html: qa.answer }} />
                        </PR.AccordionTab>
                      ))}
                    </PR.Accordion>
                  ) : (
                    <div style={{ padding: "10px", color: "#999", textAlign: "left" }}>{labels?.LBL0131}</div>
                  )}
                </>
              ) : (
                <PR.TabView activeIndex={activeIndex} onTabChange={e => {
                  setActiveIndex(e.index)
                  tabChangeHandler(e.index);
                  setFaqActiveIndex(null)
                }}>
                  {Object.entries(categories).map(([categoryKey, category]) => {
                    return (
                      <PR.TabPanel header={category.name} key={categoryKey} >
                        {filterFaqs.length > 0 ? (
                          <PR.Accordion scrollIntoView activeIndex={faqActiveIndex} onTabChange={e => {
                            setFaqActiveIndex(e.index);
                            faqTabChangeHandler(e.index);
                          }}>
                            {filterFaqs.map((qa, j) => (qa?.question &&
                              <PR.AccordionTab header={qa.question} key={j} id={generateSlug(qa.question)}>
                                <div dangerouslySetInnerHTML={{ __html: qa.answer }} />
                              </PR.AccordionTab>
                            ))}
                          </PR.Accordion>
                        ) : (
                          <div style={{ padding: "10px" }}>{labels?.LBL0131}</div>
                        )}
                      </PR.TabPanel>
                    );
                  })}
                </PR.TabView>
              )
            }
          </div>
        </div>
      </section>

      <section className="inner-header-section aboutus-header-section support-section">
        <SupportMailsSection />
      </section>
    </HelmetProvider>
  );
};

export default Faq;



import { createSlice } from '@reduxjs/toolkit';
import messagesData from '../assets/data/assets.json'

const initialMessageData = messagesData;
export const messageCatalogSlice = createSlice({
    name: 'catalogIds',
    initialState: initialMessageData,
    reducers: {
        storeMessageCatalog(state, action) {
            state.messageCatalog = action.payload.messageCatalog;
        },
        storeLabels(state, action) {
            state.labels = action.payload.labels;
        },
        storeValidations(state, action) {
            state.validations = action.payload.validations;
        },
        storeMyAccount(state, action) {
            state.myAccount = action.payload.myAccount;
        },
        storeAboutUs(state, action) {
            state.aboutUs = action.payload.aboutUs;
        },
        storeGeneral(state, action) {
            state.general = action.payload.general;
        },
        storeThemeColors(state, action) {
            state.themeColors = action.payload.themeColors;
        },
        storeHome(state, action) {
            state.home = action.payload.home;
        },
        storeContact(state, action) {
            state.contact = action.payload.contact;
        },
        storeHeader(state, action) {
            state.header = action.payload.header;
        },
        storeRedeemVoucher(state, action) {
            state.redeemVoucher = action.payload.redeemVoucher;
        },
        storeFaq(state, action) {
            state['faq-new']= action.payload;
        },
        storePolicyTerms(state, action) {
            state.policyTerms = action.payload.policyTerms;
        },
    },
})

export const messageCatalogActions = messageCatalogSlice.actions;
export default messageCatalogSlice.reducer;

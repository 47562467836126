import React from "react";
import "../redeem-voucher/RedeemVoucher.scss";
import * as PR from "../../prime-modules/index";
import { sucessIcon } from "../../assets/images";
import PaymentSummary from "../../components/payment/PaymentSummary";
import { useToast } from "../../context/ToastContext";

const RedeemVoucherSuccess = ({redeemVoucherPayRes}) => {
    const { redeemVoucher } = useToast();
    return (
        <section className="voucher-section">
            <div className="grid header-text">
                <div className="col-12">
                    <div className="vouchers-box">
                        <div className="voucher-success-section">
                            <div className="sucess">
                                <PR.Image className="logo" src={sucessIcon} alt="logo" /> <span>{redeemVoucher.VOUC014}</span>
                            </div>
                            <PaymentSummary redeemVoucher={true} redeemVoucherPayRes={redeemVoucherPayRes} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RedeemVoucherSuccess;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import { payMongoPaymentSession } from "../../services/api";
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../context/ToastContext";
import * as PR from '../../prime-modules/index';
import { paymayaIcon, grabIcon, gcashIcon } from "../../assets/images";
import { envConfig, globalConfig } from "../../constants";

const PayMongo = ({updatePmgIntent, setUpdatePmgIntent}) => {
    const dispatch = useDispatch()
    const { showToast } = useToast();
    const cartData = useSelector((state) => state.cart.catalogCart);
    const iccid = useSelector(state => state.esim.esimIccid);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const { price, name, description, dataAmount, duration, speed,promoCode } = cartData ?? {};
    const userData = useSelector(state => state.auth.sessionData);
    
    const headers = useMemo(() => {
        return { sessionid: userData.sessionId ? userData.sessionId : "", afid: affiliateTrackingData };
    }, [userData.sessionId, affiliateTrackingData]);

    const headersPayMongo = useMemo(() => ({
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Basic ${btoa(process.env.REACT_APP_PAY_MONGO_PUBLIC_KEY)}`
    }), []);
    const [intent, setIntent] = useState("");
    const [intentSession, setIntentSession] = useState({})
    const [paymentIntentClientKey, setpaymentIntentClientKey] = useState("")
    const [randomId, setRandomId] = useState('')
    const [pmId, setPmId] = useState('')


    const paymentIntent = useCallback(() => {
        if (!userData?.sessionId) {
            return
        }

        const customerDetails = {
            firstName: userData?.firstName ?? "",
            lastName: userData?.lastName ?? "",
            email: userData?.email ?? "",
            phone: {
                callingCode: "",
                localPhoneNumber: ""
            }
        }

        const dataPlans = [
            {
                name,
                description,
                quantity: 1,
                price,
                speed,
                duration,
                dataAmount,
                promoCode
            },
        ];
        const deviceInfo = { device: "website" };
        const paymentData = {
            contactInfo: customerDetails,
            orderSummary: {
                dataPlans: dataPlans,
                totalOrderAmount: price
            },
            iccid: iccid ?? "",
            paymentGateway: "PayMongo",
            paymentMethodId: uuidv4(),
            randomId: uuidv4(),
            isEsimCompatible: false,
            deviceInfo
        }
        setRandomId(paymentData.randomId)
        const getResponse = (response) => {
            if (response?.result === "SUCCESS") {
                setIntentSession(response?.data)
                setpaymentIntentClientKey(response?.data?.sessionClientSecret)
                setIntent(response?.data?.paymentIntentId)
            } else {
                const error = response.error;
                showToast(error.severity, error.errorMsg);
            }
        };
        payMongoPaymentSession(paymentData, headers, dispatch, getResponse)
    }, [dataAmount, description, dispatch, duration, headers, iccid, name, price, promoCode, showToast, speed, userData?.email, userData?.firstName, userData?.lastName, userData?.sessionId])


    useEffect(() => {
        if(updatePmgIntent) {
            setUpdatePmgIntent(false)
            paymentIntent()
        }
    }, [paymentIntent, setUpdatePmgIntent, updatePmgIntent])

    useEffect(() => {
        if (intent && pmId) {
            const attachRequestObj = { data: { attributes: { payment_method: pmId, client_key: paymentIntentClientKey, return_url: envConfig.REACT_APP_BASE_URL + `/payment-success?random_id=${randomId}&orderid=${intentSession?.orderID}&paymentGateway=paymongo` } } }
            axios.post(`${globalConfig.payMongoURL}/payment_intents/${intent}/attach`, attachRequestObj, { headers: headersPayMongo }).then(res => {
                if (res?.data?.data?.attributes?.status === "awaiting_next_action") {
                    window.location.href = res?.data?.data?.attributes?.next_action?.redirect?.url
                }
            }).catch(err => {
                console.log(err);
            })
        }
    }, [headers, intent, pmId, paymentIntentClientKey, headersPayMongo, randomId, intentSession?.orderID])

    const onSubmitHandler = (type) => {
        const requestObj = {
            data: {
                attributes: {
                    type
                }
            }
        }
        axios.post(`${globalConfig.payMongoURL}/payment_methods`, requestObj, { headers: headersPayMongo }).then(res => {
            setPmId(res?.data?.data?.id)
        }).catch(error => {
            console.log(error);
        })
    };

    return (
        <div className="paymongo-payment-section">
            <PR.Button onClick={() => onSubmitHandler('gcash')} type="submit">
                <PR.Image src={gcashIcon} alt="GCash" className="payment-img2" />
            </PR.Button>
            <PR.Button onClick={() => onSubmitHandler('paymaya')} type="submit">
                <PR.Image src={paymayaIcon} alt="PayMaya" className="payment-img2" />
            </PR.Button>
            <PR.Button onClick={() => onSubmitHandler('grab_pay')} type="submit">
                <PR.Image src={grabIcon} alt="GrabPay" className="payment-img2" />
            </PR.Button>
        </div>
    );
};

export default PayMongo;

import React, { useEffect, useState } from "react";
import "../terms-and-conditions/TermsAndConditions.scss";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useToast } from "../../context/ToastContext";

const PrivacyPolicy = () => {
  const { policyTerms, general, labels } = useToast();
  const [loading, setLoading] = useState(false);
  const privacyPolicy = !!policyTerms?.privacyPolicy
    ? JSON.parse(policyTerms.privacyPolicy)
    : {};

  useEffect(() => {
    if (policyTerms === undefined) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [policyTerms]);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`${general.GEN0001} | ${labels.LBL0075}`}</title>
        </Helmet>
        {loading ? (
          <section className="minHeight">
            <div className="loading-block">
              <i
                className="pi pi-spin pi-spinner"
                style={{ fontSize: "2rem" }}
              ></i>
              <p>{labels.LBL0069}</p>
            </div>
          </section>
        ) : (
          <div className="p-4 terms-conditions">
            <p>
              <strong>{privacyPolicy.title}</strong>
            </p>
            <div
              dangerouslySetInnerHTML={{ __html: privacyPolicy.description }}
            />
          </div>
        )}
      </HelmetProvider>
    </>
  );
};

export default PrivacyPolicy;

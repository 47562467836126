import React, { useEffect, useMemo, useState } from "react";
import * as PR from "../../prime-modules/index";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { isIOS, osVersion, isMobile } from 'react-device-detect';
import { cartFilterActions } from "../../store/cart";
import { affiliateTrackingInfoActions } from "../../store/affiliateTracking";
import { Checkmark } from "../../assets/images";
import EsimActivationDialog from "../../shared/components/EsimActivationDialog";
import HeaderMenu from "../layout/HeaderMenu";
import { useToast } from "../../context/ToastContext";
import { getPaymentDetailsAPI, payMongoPaymentSessionCheckout } from "../../services/api";
import { temporaryTranslations } from "../../assets/data/temporaryTranslations";

const PaymentSummary = ({redeemVoucher, redeemVoucherPayRes}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { labels } = useToast();
    const { showToast } = useToast();

    const userData = useSelector(state => state.auth.sessionData);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const { search, state, pathname } = useLocation();
    const queryParams = new URLSearchParams(search);
    const paymentGateway = queryParams.get('paymentGateway');
    const orderid = queryParams.get('orderid');
    const random_id = queryParams.get('random_id');
    
    const [loading, setLoading] = useState(false);

    const [activeeSIM, setActiveeSIM] = useState(false);
    const payRes = redeemVoucherPayRes ? redeemVoucherPayRes : state?.payRes;

    const headers = useMemo(() => {
        return { sessionid: userData.sessionId ? userData.sessionId : "", afid: affiliateTrackingData };
    }, [userData.sessionId, affiliateTrackingData]);

    useEffect(() => {
        const queryUrl = `?orderid=${orderid}&random_id=${random_id}`;
        const getResponse = (response) => {
        if (response?.result === "SUCCESS") {
          setLoading(false);
          if(response?.data?.transactionStatus !== "COMPLETED") {
            navigate("/");
          } else {
              navigate(pathname, { replace: true, state: {payRes: response?.data} });
            }
        } else {
          setLoading(false);
          const error = response.error;
          showToast(error.severity, error.errorMsg);
          navigate("/");
        }
      };
      if (!payRes && !loading && !redeemVoucher) {
        setLoading(true);
        if (paymentGateway === "paymongo") {
            payMongoPaymentSessionCheckout(queryUrl, headers, dispatch, getResponse);
        } else {
            getPaymentDetailsAPI(queryUrl, headers, dispatch, getResponse);
        }
      } else if(!search && !payRes?.paymentResponse) {
        navigate("/")
      }
      
    }, [dispatch, headers, loading, navigate, orderid, pathname, payRes, paymentGateway, random_id, redeemVoucher, search, showToast]);

    const downloadQRCode = (qrcode) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = qrcode;
        downloadLink.download = "QR_Code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    useEffect(() => {
        if(payRes){
            dispatch(cartFilterActions.deleteCatalogCart());
            dispatch(cartFilterActions.setCatalogCartAmount(0));
            dispatch(affiliateTrackingInfoActions.deleteAffiliateTrackingInfo());
        }
    }, [dispatch, payRes])
    
    return (
        <>
            {!redeemVoucher &&
                <section className="inner-header-section aboutus-header-section payment_summary_header">
                    <div className="header">
                        <header>
                            <HeaderMenu />
                        </header>
                        <div className="fixed-height"></div>
                    </div>
                </section>
            }
            {
                payRes && ( <>
                    <section className="paymentsummary-qr-section">
                        <div className="payment-summary">
                            {!redeemVoucher &&
                                <>
                                    <PR.Image src={Checkmark} alt={labels.LBL0052} />
                                    <h2>{labels.LBL0052}</h2>
                                    <p>{labels.LBL0053}</p>
                                </>
                            }
                            {payRes?.qrCode && (payRes?.qrCode !== '') ?
                                 <>
                                 <span className="scan-text">{labels.LBL0054}</span>

                                {payRes?.qrCode && payRes?.paymentResponse?.smdpAddress && <img src={`data:image/png;base64,${payRes?.qrCode}`} id="qrCodeEl" alt="qrcode" className="qr-code" />}

                                 {payRes?.email && payRes?.email !== "" &&
                                     <span className="scan-text mt-3">{labels.LBL0055} <span className="payer-email"> {payRes?.email}</span> </span>
                                 }
                                 {payRes?.paymentResponse?.smdpAddress && payRes?.paymentResponse?.matchingId &&
                                     <EsimActivationDialog smdpAddress={payRes?.paymentResponse?.smdpAddress} matchingId={payRes?.paymentResponse?.matchingId} activeeSIM={activeeSIM} setActiveeSIM={setActiveeSIM} />}
                                 <br />
                                 <PR.Button type="button" label={labels.LBL0056} value={labels.LBL0056} className="download-button" onClick={() => downloadQRCode(`data:image/png;base64,${payRes?.qrCode}`)} />
                                 {(isMobile && isIOS && (osVersion >= "17.4")) && <PR.Button onClick={() => setActiveeSIM(true)} type="button" label={labels.LBL0057} className="download-button" />}
                             </> : payRes?.status !== "in_progress" &&
                             <span className="scan-text">{labels.LBL0058}</span>
                            }
                                {payRes?.status === "in_progress" &&
                                <div style={{minHeight:"200px"}}>
                                    <p className="scan-text">{labels.LBL0174 || temporaryTranslations.LBL0174}</p>
                                </div>
                                }
                        </div>
                    </section>
                </>)
            }
            { loading && !payRes &&
                <section className="payment-loading">
                    <div className="loading-block">
                        <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>
                        <p>{labels.LBL0069}</p>
                    </div>
                </section>
            }
        </>
    );
};

export default PaymentSummary;